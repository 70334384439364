import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { AngularMyDatePickerModule } from 'gramli-angular-mydatepicker';

import { RsResolveElementDirective } from './rs-resolve/rs-resolve.directive';
import { RsTooltipDirective } from './rs-tooltip/rs-tooltip.directive';
import { RsMaskDirective } from './rs-mask/rs-mask.directive';
import { RsUIComponent } from './rs-ui/rs-ui.component';
import { RsErrorMessageComponent } from './rs-error-message/rs-error-message.component';
import { RsFormComponent } from './rs-form/rs-form.component';
import { RsFormGroupComponent } from './rs-form-group/rs-form-group.component';
import { RsConsumerGroupComponent } from './rs-consumer-group/rs-consumer-group.component';
import { RsTabbedConsumerGroupComponent } from './rs-consumer-group/rs-tabbed-consumer-group/rs-tabbed-consumer-group.component';
import { RsDropdownConsumerGroupComponent } from './rs-consumer-group/rs-dropdown-consumer-group/rs-dropdown-consumer-group.component';
import { RsFormFactory } from './rs-form/rs-form-factory.class';
import { RsGridComponent } from './rs-grid/rs-grid.component';
import { RsFormDispatcher } from './rs-form/rs-form.store';
import { RsGridStore, RsGridDispatcher } from './rs-grid/rs-grid.store';

import { OnSubmitAction } from './rs-store/actions/rs-form.actions';
import { RsDatepicker } from './datepicker/datepicker.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { NgxMaskModule } from 'ngx-mask';
import { TabsComponentModule } from './tabs/tabs.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        AngularMyDatePickerModule,
        TranslateModule,
        ClickOutsideModule,
        NgxMaskModule.forChild(),
        TabsComponentModule,
    ],
    declarations: [
        RsUIComponent,
        RsErrorMessageComponent,
        RsFormComponent,
        RsFormGroupComponent,
        RsConsumerGroupComponent,
        RsTabbedConsumerGroupComponent,
        RsDropdownConsumerGroupComponent,
        RsGridComponent,
        RsTooltipDirective,
        RsMaskDirective,
        RsResolveElementDirective,
        RsDatepicker,
        DropdownComponent,
    ],
    providers: [OnSubmitAction, RsFormFactory, RsFormDispatcher, RsGridStore, RsGridDispatcher, TranslateModule],
    exports: [
        RsUIComponent,
        RsErrorMessageComponent,
        RsFormComponent,
        RsFormGroupComponent,
        RsConsumerGroupComponent,
        RsTabbedConsumerGroupComponent,
        RsDropdownConsumerGroupComponent,
        RsGridComponent,
        RsTooltipDirective,
        RsMaskDirective,
        RsResolveElementDirective,
        TranslatePipe,
        RsDatepicker,
        DropdownComponent,
        TabsComponentModule,
    ],
})
export class RsCoreModule {}
