export class InsuranceBenefit {
    public id: number;
    public inNetworkBenefit: any;
    public outNetworkBenefit: any;
    public policyInfo: any;
    public discrepancies: any;
    public benefitStatus: BenefitStatus;
    public benefitStatusDetails: string;
    public createdAt: Date;
};


export enum BenefitStatus {
    Active = 1,
    NotActive = 2,
    MemberNotFound = 3,
    ResultsPending = 4,
    PayerUnavailable = 5,
    InsufficientSearchCriteria = 6,
    Error = 7,
    NotRun = 8,
    NotApplicable = 9,
    InsuranceNotUsed = 10,
    UnmappedPayer = 11,
    ProviderEnrollmentIssue = 12,
};

export const BenefitStatusLabels = {
    [BenefitStatus.Active]: 'Active',
    [BenefitStatus.NotActive]: 'Not Active',
    [BenefitStatus.MemberNotFound]: 'Member Not Found',
    [BenefitStatus.ResultsPending]: 'Results Pending',
    [BenefitStatus.PayerUnavailable]: 'Payer Unavailable',
    [BenefitStatus.InsufficientSearchCriteria]: 'Insufficient Search Criteria',
    [BenefitStatus.Error]: 'Error',
    [BenefitStatus.NotRun]: 'Not Run',
    [BenefitStatus.NotApplicable]: 'Not Applicable',
    [BenefitStatus.InsuranceNotUsed]: 'Insurance Not Used',
    [BenefitStatus.UnmappedPayer]: 'Unmapped Payer',
    [BenefitStatus.ProviderEnrollmentIssue]: 'Provider Enrollment Issue',
  };