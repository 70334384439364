<ng-content select="[header]"></ng-content>

<ul role="tablist" aria-orientation="horizontal" [ngClass]="wrapperContainer()">
  <li *ngFor="let tab of tabs; let i = index" [ngClass]="css()">
    <a
      role="tab"
      [attr.aria-selected]="tab.active"
      [attr.aria-controls]="tab.tabTitle + 'panel'"
      class="text-center nav-link fw-700"
      [ngClass]="tabHeaderCss()"
      [id]="tab.title"
      [class.active]="tab.active"
      (click)="setActiveTab(i)"
      href="javascript:">
      <span *ngFor="let span of tab.additionalSpans" [ngClass]="span.class"
        >{{ span.innerHtml }}
      </span>
      {{ tab.tabTitle }}
    </a>
  </li>
</ul>

<ng-content></ng-content>
