import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularMyDatePickerModule } from 'gramli-angular-mydatepicker';
import { TranslateModule } from '@ngx-translate/core';

// RS Service2
import { BASE_PATH, Configuration } from '@em3';

// RS Core
import { RsCoreModule } from '@revspringinc/rs-core';

// Services
import { FormsService } from './services/forms.service';
import { SharedPipeModule } from './pipes/pipe.module';

import { RadioItemComponent } from './components/radio-item/radio-item.component';
import { RsSearchComponent } from './components/search-field/rs-search.component';
import { SpinnerModule } from './components/spinner/spinner.component';

import { CollapseModule } from './components/collapse/collapse.component';
import { PopoverComponent } from './components/popover/popover.component';
import { AlertComponent } from './components/alert/alert.component';
import { ExternalRedirectGuard } from './guards/external-redirect.guard';
import { AmaNoticeService } from './services/ama-notice.service';
import { UnsupportedBrowserService } from './services/unsupported-browser.service';
import { PaCurrencyPipe } from './pipes/currency.pipe';
import { PrepaymentService } from './services/prepayment-service';
import { ModalsModule, RsFormsModule } from '@revspringinc/rs-shared';
import { ToastModule } from './components/toast/toast.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        ReactiveFormsModule,
        FormsModule,
        AngularMyDatePickerModule,
        ModalsModule,
        RsFormsModule,
        RsCoreModule,
        SharedPipeModule,
        ToastModule,
        CollapseModule,
        SpinnerModule,
    ],
    declarations: [AlertComponent, PopoverComponent, RadioItemComponent, RsSearchComponent],
    exports: [AlertComponent, PopoverComponent, RadioItemComponent, RsSearchComponent, CollapseModule, SpinnerModule],
    schemas: [NO_ERRORS_SCHEMA],
    providers: [
        AmaNoticeService,
        FormsService,
        PrepaymentService,
        UnsupportedBrowserService,
        ExternalRedirectGuard,
        PaCurrencyPipe,
        {
            provide: BASE_PATH,
            useValue: location.origin + '/api',
        },
        {
            provide: Configuration,
            useValue: new Configuration({}),
            multi: false,
        },
    ],
})
export class RsSharedModule {}
